import React from "react"
import { graphql } from "gatsby"

import ServiceLayout from "../../components/Services/ServiceLayout"
import ServiceSection from "../../components/Services/ServiceSection"
import CTA from "../../components/CTA"
import SEO from "../../components/SEO"

const RMT = ({ data }) => {
  const contentA = (
    <>
      <p>
        Our registered massage therapist (RMT) services provide manual treatment
        that aims to change the amount of tone or tension in different areas of
        the body. We focus on soft tissue, including muscles, fascia (the
        connective tissue around the muscles) and even skin, which can also hold
        tension.
      </p>
      <p>
        This type of massage provides a window of opportunity for more
        comfortable movement and reduced pain. Our RMT offers both deep tissue
        and myofascial release, understanding when to apply deep pressure but
        also when to use a lighter touch. We will work with your body to assess
        how the tissue is responding and adapt our approach to suit your needs.
      </p>
    </>
  )
  return (
    <ServiceLayout serviceTitle="Registered Massage Therapy">
      <SEO title="RMT" />
      <ServiceSection
        content={contentA}
        image={data.photoOne}
        subTitle="Hands-on therapy to help you achieve comfortable movement"
        optionB
      />

      <CTA
        title="Discover your personalized plan to get the movement you want"
        text="Don’t let your body hold you back from doing what you love. Find out how you can reduce pain and improve your movement today."
        buttonText="Book a consultation"
        to="https://artandsciencephysio.janeapp.com/#/staff_member/1/treatment/8"
      />
    </ServiceLayout>
  )
}

export const query = graphql`
  query RMTQuery {
    photoOne: file(relativePath: { eq: "2022/007A5460.jpg" }) {
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 75, width: 650)
      }
    }
  }
`

export default RMT
